<template>
  <span class="text-gray title">Packs Balance</span>
  <span v-if="!totalPacksLoading" class="text-white font-size-300">${{ $fixPrice(totalBudget, 2) }}</span>
  <span v-else class=""><img src="@/assets/loader.svg" height="20" alt=""></span>
  <span class="increase-number">
    <span v-if="percBudgetGainLose > 0" class="text-success mr-1 font-size-080">
      <font-awesome-icon :icon="['fas', 'chevron-up']" />
    </span>
    <span v-if="percBudgetGainLose < 0" class="text-danger mr-1 font-size-080">
      <font-awesome-icon :icon="['fas', 'chevron-up']" />
    </span>
    <span :class="[{'text-danger':percBudgetGainLose < 0},{'text-success':percBudgetGainLose >= 0}]">
      {{$fixPrice(percBudgetGainLose,2)}}%
    </span>
  </span>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PacksBalance',
  data() {
    return {
      totalPacksLoading: true,
      packsList: [],
      totalBudget: 0,
      percBudgetGainLose: 0
    };
  },
  created() {
    this.totalValue = 0;

    axios.get(`/cryptos/packs`)
      .then((response)=> {
        this.packsList = response.data.body;
        this.packsList.forEach(async (elem, index) =>{
          elem.totalValue = 0;
          for(let i=0; i < elem.cryptocurrencies.length; i++){
            this.$getSymbolPrice(elem.cryptocurrencies[i].symbol,elem.cryptocurrencies[i].source_price, index, elem.cryptocurrencies[i].id_coinmarketcap)
              .then((price) => {
                this.packsList[index].cryptocurrencies[i]['price'] = price;
                this.packsList[index].totalValue += parseFloat(price) * this.packsList[index].cryptocurrencies[i].owned;
                if(i == elem.cryptocurrencies.length - 1){
                  this.calculateTotalBudgets();
                  this.$emit('widgetLoaded');
                }
              })
              .catch((err)=>{
                this.totalPacksLoading = false;
              })
          }
        });
        this.totalPacksLoading = false;
      });
    
  },
  methods: {
    calculateTotalBudgets(){
      let totalValue = 0;
      let totalInvestedCapital = 0;
      this.packsList.forEach((budget,index) => {
        totalValue += parseFloat(budget.totalValue);
        totalInvestedCapital += parseFloat(budget.invested_capital);
      })
      this.totalBudget = totalValue - totalInvestedCapital;
      this.percBudgetGainLose = (totalValue * 100 / totalInvestedCapital) - 100;
      this.totalPacksLoading = false;

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
