<template>
  <div id="MyCryptos" class="editable-widget">
    <div class="row mb-5 kpis">
      <div class="col-12 col-md-4">
        <div class="card h-100">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <span class="text-gray title">Wallet Balance</span>
            <span v-if="totalValueComputed > 0" class="text-success font-size-300">${{$fixPrice(totalValueComputed,2)}}</span>
            <span v-else class="mt-3"><img src="@/assets/loader.svg" height="20" alt=""></span>
            <span class="text-success increase-number"></span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card h-100">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <PacksBalance></PacksBalance>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4" v-if="myCryptosList.length > 0">
        <div class="card h-100">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <span class="text-gray title mb-3">Portfolio Crypto</span>
            <div style="height:150px">
              <canvas id="pieChartCryptos"></canvas>
            </div>
            <span class="loaderPIE" v-if="totalValueLoading"><img src="@/assets/loader.svg" height="20" alt=""></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h2 class="h4 d-flex justify-content-between align-items-center mb-0">
          <div>
            <span class="font-size-125 font-family-krona">My Wallet</span>
          </div>
          <div class="d-inline-flex align-items-center">
            <span v-if="myCryptosList.length > 0" id="dropdownMenuButtonMyCryptos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="ellipsis-icon pr-2 pl-3"><font-awesome-icon :icon="['fas', 'ellipsis-v']" /></span>
            <div v-if="myCryptosList.length > 0" class="dropdown-menu dropdown-menu-right py-0" aria-labelledby="dropdownMenuButtonMyCryptos">
              <a @click="increaseIndex()" href="javascript:void(0)" title="Edit" class="dropdown-item border-bottom" data-toggle="modal" data-target="#EditMyCryptos"><span class="icon"><font-awesome-icon class="mr-2" :icon="['far', 'edit']" /></span>Edit wallet</a>
              <a @click="increaseIndex()" href="javascript:void(0)" title="Import" class="dropdown-item border-bottom" data-toggle="modal" data-target="#ImportCrypto"><span class="icon"><font-awesome-icon class="mr-2" :icon="['fas', 'coins']" /></span>Import crypto</a>
              <a v-if="$checkMetamask() && accounts > 0" @click="increaseIndex()" href="javascript:void(0)" title="Sync form wallet" class="dropdown-item " data-toggle="modal" data-target="#SyncFromWallet"><span class="icon"><font-awesome-icon class="mr-2" :icon="['fas', 'wallet']" /></span>Sync from wallet</a>
            </div>
          </div>
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row" v-if="myCryptosList.length > 0">
          <template v-for="(crypto, index) in myCryptosList" v-bind:key="index">
            <template v-if="index < 3">
              <div class="col-12 col-md-4 mb-5">
                <div class="card">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-12 d-flex align-items-center justify-content-between">
                        <div class="d-flex">
                          <div class="mr-3">
                            <img class="float-left" width="48" :src="crypto.img"/>
                          </div> 
                          <div class="d-flex flex-column">
                            <span class="font-size-150">{{ crypto.symbol }}</span>
                            <span class="text-gray">{{ crypto.name }}</span>
                          </div>
                        </div>
                        <div class="position-relative d-flex" style="height: 60px">
                          <canvas :id="'myChart'+index"></canvas>
                          <span class="chart-percentaje">{{$fixPrice((crypto.last_price * crypto.owned * 100 / totalValueComputed),2)}} %</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-5 text-left text-gray d-flex align-items-center">
                        <span class="text-gray font-size-125" v-if="crypto.last_price" >
                          $<span v-html="$fixPrice(crypto.last_price, crypto.decimals)"></span>
                        </span>
                        <span v-else class="text-left float-left w-100 text-gray">
                          <img src="@/assets/loader.svg" height="20" alt="">
                        </span>
                      </div>
                      <div class="col-7 text-right">
                        <span v-if="crypto.last_price" class="font-size-200">${{$formatRoundNumber($fixPrice(crypto.last_price * crypto.owned, 2))}}</span>
                        <span v-else class="text-right float-left w-100"><img src="@/assets/loader.svg" height="20" alt=""></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>

        <div class="card" v-if="myCryptosList.length > 0">
          <div class="card-body px-3 py-2">
            <div class="row">
              <div class="col-lg-6 col-xl-4 col-12" v-for="(crypto, index) in myCryptosList" v-bind:key="index">
                <template v-if= "index > 2">
                  <router-link class="no-link" :to="{ name: 'crypto-detail', params: { address: crypto.address || 0, chain: crypto.chain || 0 }, query: { symbol: crypto.symbol}}">
                      <div class="mb-2">
                        <div class="row justify-content-between py-2">
                          <div class="col-6 d-flex align-items-center">
                            <div class="mr-3">
                              <img class="float-left" width="32" :src="crypto.img"/>
                            </div> 
                            <div class="d-flex flex-column">
                              <span>{{ crypto.symbol }}</span>
                              <span class="text-gray font-size-090" v-if="crypto.last_price" >
                                $<span v-html="$fixPrice(crypto.last_price, crypto.decimals)"></span>
                              </span>
                              <span class="text-gray font-size-090" v-else-if="crypto.last_price == 0" >
                                <span class="text-danger" title="It has not been possible to obtain the price"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /></span>
                              </span>
                              <span v-else class="text-center float-left w-100 text-gray">
                                <img src="@/assets/loader.svg" height="20" alt="">
                              </span>
                            </div>
                          </div>
                          <div class="col-6 d-flex justify-content-end text-right flex-column">
                            <div class="">
                              <span v-if="crypto.last_price">${{$formatRoundNumber($fixPrice(crypto.last_price * crypto.owned, 2))}}</span>
                              <span v-else-if="crypto.last_price == 0" >
                                <span class="text-danger" title="It has not been possible to obtain the price"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /></span>
                              </span>
                              <span v-else class="text-right float-left w-100"><img src="@/assets/loader.svg" height="20" alt=""></span>
                            </div>
                            <div class="text-gray font-size-090">
                              <span :title="crypto.owned">{{ $formatRoundNumber(crypto.owned) }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="myCryptosList.length <= 0 && loadingEnd" class="col-12">
        <div class="card mb-3 pack-item text-center">
          <div class="card-body pb-2">
            <div class="alert alert-info">You haven't added cryptos to your wallet yet. <a href="javascript:void(0)" data-toggle="modal" data-target="#EditMyCryptos" >Add your first crypto</a> or <a href="javascript:void(0)" data-toggle="modal" data-target="#ImportCrypto" >import new one</a> </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <EditMyCryptos :id="'EditMyCryptos'" :modalTitle="'EDIT MY CRYPTOS'" :MyCryptosList="myCryptosList" :index="indexUpdate" :classes="'p-0'" :class_body="'pt-0'" @userCryptosEdited="loadList()"></EditMyCryptos>
  <ImportCrypto :id="'ImportCrypto'" :modalTitle="'IMPORT CRYPTO'" :MyCryptosList="myCryptosList" :index="indexUpdate" :classes="'p-0'" @userCryptosEdited="loadList()"></ImportCrypto>
  <SyncFromWallet :id="'SyncFromWallet'" :modalTitle="'SYNC FROM WALLET CRYPTO'" :MyCryptosList="myCryptosList" :index="indexUpdate" :classes="'p-0'" @userCryptosEdited="loadList()"></SyncFromWallet>
</template>

<script>
import axios from 'axios';
import EditMyCryptos from './templates/EditMyCryptos.vue'
import ImportCrypto from './templates/ImportCrypto.vue'
import SyncFromWallet from './templates/SyncFromWallet.vue'
import PacksBalance from './templates/PacksBalance.vue'

export default {
  name: 'MyCryptos',
  data() {
    return {
      myCryptosList: [],
      totalValue: 0,
      totalValueLoading: true,
      indexUpdate: 0,
      loadingEnd: false,
      intervalPrice: '',
      accounts: '',
    };
  },
  computed: {
    // un getter computado
    totalValueComputed: function () {
      return this.myCryptosList.reduce((acc, item) => acc + parseFloat(item.last_price * item.owned), 0);
    }
  },
  components: {
    EditMyCryptos,
    ImportCrypto,
    SyncFromWallet,
    PacksBalance
  },
  created() {
    this.loadList();

    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result
      });
    }

  },
  updated() {
    if(this.$checkMetamask()){
      this.$getAccountsMetamask().then((result) => {
        this.accounts = result
      });
    }
  },
  mounted() {
    //this.initCharts();
  },
  methods: {
    loadList(){
      axios.get(`/cryptos/user/my-cryptos`)
      .then(async (response)=> {
        this.$emit('widgetLoaded');
        this.myCryptosList = response.data.body.user_cryptos;
        this.loadingEnd = true;
        this.pieChartCryptos();
        this.loadPrices2();
        //this.loadPrices();
      })
    },
    async loadPrices(){/*
      console.log('LOAD PRICES');
      this.totalValueLoading = true;
      this.totalValue = 0;
      for(let i=0; i < this.myCryptosList.length; i++){

        this.myCryptosList[i].address = this.myCryptosList[i].contracts ? this.myCryptosList[i].contracts[0].id : '0'; 
        this.myCryptosList[i].chain = this.myCryptosList[i].contracts ? this.myCryptosList[i].contracts[0].chain : '0';

        await this.$getSymbolPrice(this.myCryptosList[i].symbol,this.myCryptosList[i].source_price, i, this.myCryptosList[i].id_coinmarketcap)
          .then((price) => {
            if(i == this.myCryptosList.length - 1){
              this.totalValueLoading = false;
            }
            this.myCryptosList[i]['price'] = price;
            this.totalValue += parseFloat(this.myCryptosList[i]['price']) * this.myCryptosList[i].owned;
          })
      }
      this.myCryptosList .sort((a, b) => b.owned*b.price - a.owned*a.price);
      this.initCharts();
      this.totalValueLoading = false;
      this.pieChartCryptos();
      */
    },
    async loadPrices2(){
      this.totalValueLoading = true;
      //this.totalValue = 0;
      let bodyRequest = [];

      let chainListService = {
        "BSC":"BSC",
        "BNB":"BSC",
        "ETHER":"ETHEREUM",
        "ETH":"ETHEREUM",
        "GOERLI":"GOERLI",
        "SEPHOLIA":"SEPHOLIA",
        "POLYGON":"POLYGON",
        "MUMBAI":"MUMBAI",
        "AVALANCHE":"AVALANCHE",
        "AVAX":"AVALANCHE",
        "FANTOM":"FANTOM",
        "CRONOS":"CRONOS",
        "PALM":"PALM",
        "ARBITRUM":"ARBITRUM",
        "OPTIMISM":"OPTIMISM",
        "RONIN":"RONIN",
        "ARB":"ARBITRUM",
        "CHILIZ":"CHILIZ",
        "GNOSIS":"GNOSIS"
      }

      for(let i=0; i < this.myCryptosList.length; i++){

        bodyRequest.push({
          "address": this.myCryptosList[i].contracts ? this.myCryptosList[i].contracts[0].id : '0',
          "symbol": this.myCryptosList[i].symbol,
          "chain": this.myCryptosList[i].contracts ? chainListService[this.myCryptosList[i].contracts[0].chain.toUpperCase()] : '0',
          "idCoinmarketcap": this.myCryptosList[i].id_coinmarketcap ? this.myCryptosList[i].id_coinmarketcap : '',
          "id": this.myCryptosList[i].ID
        });

      }
      
      axios.post(`/v2/tokens/priceByGroup`,bodyRequest)
        .then(async (response)=> {
          console.log(response.data);
          
          for(let i=0; i < this.myCryptosList.length; i++){
            if(response.data.body[i].priceUSD > 0){
              this.myCryptosList[i]['last_price'] = response.data.body[i].priceUSD;
            }  
            //this.totalValue += parseFloat(this.myCryptosList[i]['price']) * this.myCryptosList[i].owned;
          }
          this.totalValueLoading = false;
          this.myCryptosList.sort((a, b) => b.owned*b.last_price - a.owned*a.last_price);
          this.initCharts();
          this.pieChartCryptos();
        })
    },
    increaseIndex(){
      this.indexUpdate++;
    },
    initCharts(){
      let charts = []
      for(let i=0; i < 3; i++){
        if(document.getElementById('myChart' + i) && !charts[i]){

          const ctx = document.getElementById('myChart' + i);

          charts[i] = new Chart(ctx, {
            type: 'doughnut',
            options: {
                cutout: 27,
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                      enabled: false
                    }
                }
            },
            data: {
              labels: [
                'Red',
                'Blue',
                'Yellow'
              ],
              datasets: [{
                label: 'My First Dataset',
                data: [this.myCryptosList[i].last_price * this.myCryptosList[i].owned, this.totalValueComputed - this.myCryptosList[i].last_price * this.myCryptosList[i].owned],
                backgroundColor: [
                  '#c76014',
                  '#747a83'
                ],
                borderWidth:0,
                hoverOffset: 4
              }]
            }
          });
        }
      }
    },
    pieChartCryptos(){
      let data = [];
      let labels = []

      for(let i=0; i < this.myCryptosList.length; i++){
        data[i] =  this.$fixPrice(this.myCryptosList[i].last_price * this.myCryptosList[i].owned,2);
        labels[i] = this.myCryptosList[i].symbol;
      };

      const ctx = document.getElementById('pieChartCryptos');
        new Chart(ctx, {
          type: 'doughnut',
          options: {
              cutout: 50,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (context) {
                        let total = 0
                        context.dataset.data.forEach((elem) =>{
                          total += parseFloat(elem);
                        })
                        let percentage = (context.parsed * 100 / total);
                        return '$' + context.raw + '('+percentage.toFixed(2)+'%)';
                    }
                  }
                }
              }
          },
          data: {
            labels: labels,
            datasets: [{
              data: data,
              backgroundColor: [
                '#c76014',
                '#747a83'
              ],
              borderWidth:1,
              borderColor: '#0b0c0e',
              hoverOffset: 1
            }]
          }
      });

    }
  },
};
</script>

<style lang="scss">
  .chart-percentaje{
    position: absolute;
    top: 0px;
    right: 0;
    font-size: 0.8rem;
    display: flex;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
  }

  .kpis{
    .card{
      min-height: 230px;
      background: #0b0c0e;
      border:2px solid #121417;
    }
    .increase-number{
      height: 30px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    .loaderPIE{
      position: absolute;
      top:50%;
    }
  }

</style>
